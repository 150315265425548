import Carousel from "react-bootstrap/Carousel";
import SampleImg from "../assets/img/Sample.png";
import { useEffect, useState } from "react";
import Box from "@mui/system/Box";
import { Typography } from "@mui/material";

function ImageCarousel(props) {
	const [ImageList, setImageList] = useState([]);

	useEffect(() => {
		console.log(props.onImageList);
		if (props.onImageList !== undefined && props.onImageList.length !== 0) {
			// let ImageArray = JSON.parse(props.onImageList);
			console.log("IMAGE LIST:", props.onImageList);

			if (Array.isArray(props.onImageList)) {
				setImageList(props.onImageList);
			} else {
				// Handle the case when onImageList is not an array
			}
		}
	}, [props]);

	useEffect(() => {
		console.log(ImageList);
	}, [ImageList]);

	return (
		<>
			<Carousel
				indicators={false}
				interval={null}
				style={{ overflow: "visible" }}>
				{ImageList != null &&
					ImageList.map((url) => {
						return (
							<Carousel.Item className='img-carousel-item'>
								<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
									<img
										src={
											process.env.REACT_APP_MODE === "PROD"
												? url
												: "https://services-parkncharge.sysnetph.com/uploads/" + url
										}
										loading='eager'
									/>
								</Box>
							</Carousel.Item>
						);
					})}
				<Typography
					variant='caption'
					sx={{
						position: "absolute",
						color: "white",
						right: "-.65%",
						bottom: "-1.65%",
						backgroundColor: "rgba(0, 0, 0, 0.5);",
						padding: "4px",
						borderRadius: "8px",
					}}>
					All images are sourced from Google Images.
				</Typography>
			</Carousel>
		</>
	);
}

export default ImageCarousel;
